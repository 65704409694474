const aaa = [
	{
		path: "purchaseList",
		component: () => import("@/views/purchase/purchaseList.vue"),
	},
  {
		path: "purchaseDetail",
		component: () => import("@/views/purchase/purchaseDetail.vue"),
	},
  
]
export default aaa;