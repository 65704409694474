const aaa = [
	{
		path: "shuju",
		component: () => import("@/views/shuju/shuju/shuju.vue"),
	},
	{
		path: "ticheng",
		component: () => import("@/views/shuju/ticheng/ticheng.vue"),
	}
]
export default aaa;