const aaa = [
	{
		path: "huiyuanList",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanList.vue"),
		meta:{keepAlive:true},
	},
	{
		path: "huiyuanSee",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanSee.vue"),
	},
	{
		path: "huiyuanYueSee",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanYueSee.vue"),
	},
	{
		path: "huiyuanJifenSee",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanJifenSee.vue"),
	},
	{
		path: "huiyuanXiaofeikaSee",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanXiaofeikaSee.vue"),
	},
	{
		path: "huiyuanYouhuiquanSee",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanYouhuiquanSee.vue"),
	},
	{
		path: "petSee",
		component: () => import("@/views/huiyuan/huiyuan/petSee.vue"),
	},
	{
		path: "huiyuanOrderSee",
		component: () => import("@/views/huiyuan/huiyuan/huiyuanOrderSee.vue"),
	},
]
export default aaa;