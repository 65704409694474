import $http from "../../api/api.js"
export default {
	state: {
		// 宠物毛发
		petColorList:[],
		// 宠物品种
		petBreedList:[],
		// 顾客星级
		memberStarList:[],

	},
	getters: {
		
	},
	mutations: {
		getPetColorList(state){
			$http.get("/cms/System/GetSystemDiction",{
				companyId:sessionStorage.getItem("companyId"),
				code:"maose",
			}).then(res=>{
				state.petColorList = res.data.itemList
			})
		},
		getPetBreedList(state){
			$http.get("/cms/System/GetSystemDiction",{
				companyId:sessionStorage.getItem("companyId"),
				code:"breed",
			}).then(res=>{
				state.petBreedList = res.data.itemList
			})
		},
		getMemberStarList(state){
			$http.get("/cms/System/GetSystemDiction",{
				companyId:sessionStorage.getItem("companyId"),
				code:"memberstar",
			}).then(res=>{
				state.memberStarList = res.data.itemList
			})
		},
		
	},
	actions: {
	},
};
