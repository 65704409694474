<template>
  <div id="app">
	<transition name="slide-left">
		<router-view></router-view>
	</transition>
  </div>
</template>

<script>
export default {
	name: 'App',
	data(){
		return {
		}
	},
	computed:{
		
	},
	mounted(){
		
	},
	methods:{
		
	},
}
</script>

<style lang="scss">
#app {
	height: 100%;width:100%;
}

/* 分页 */
.paging{
	position: absolute;bottom:0;right: 0;
}

/* 超链接颜色 */
.linkColor{
	color:$mainColor;cursor: pointer;
}

.asideNavTooltip{
	margin-left:-70px !important;
}
// 预约-日历
.el-calendar-table .el-calendar-day {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 8px;
	height: 50px
}
</style>
