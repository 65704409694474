const aaa = [
	{
		path: "chanpinList",
		component: () => import("@/views/cangku/chanpin/list.vue"),
		meta:{title:'产品信息',keepAlive:true},
	},
	{
		path: "goodsDetail",
		component: () => import("@/views/cangku/chanpin/goodsDetail.vue"),
	},
	{
		path: "mingxi",
		component: () => import("@/views/cangku/chanpin/mingxi.vue"),
	},
	{
		path: "comboDetail",
		component: () => import("@/views/cangku/chanpin/comboDetail.vue"),
	},
	{
		path: "serviceDetail",
		component: () => import("@/views/cangku/chanpin/serviceDetail.vue"),
	},
	{
		path: "virtualDetail",
		component: () => import("@/views/cangku/chanpin/virtualDetail.vue"),
	},
	{
		path: "couponDetail",
		component: () => import("@/views/cangku/chanpin/couponDetail.vue"),
	},
	{
		path: "huotiDetail",
		component: () => import("@/views/cangku/chanpin/huotiDetail.vue"),
	},
	{
		path: "couponRecord",
		component: () => import("@/views/cangku/chanpin/couponRecord.vue"),
	},
	{
		path: "yujingList",
		component: () => import("@/views/cangku/yujing/list.vue"),
	},
	{
		path: "rukuList",
		component: () => import("@/views/cangku/ruku/list.vue"),
	},
	{
		path: "rukuAdd",
		component: () => import("@/views/cangku/ruku/add.vue"),
	},
	{
		path: "rukuReturn",
		component: () => import("@/views/cangku/ruku/return.vue"),
	},
	{
		path: "chukuList",
		component: () => import("@/views/cangku/chuku/list.vue"),
	},
	{
		path: "chukuAdd",
		component: () => import("@/views/cangku/chuku/add.vue"),
	},
	{
		path: "pandianList",
		component: () => import("@/views/cangku/pandian/list.vue"),
	},
	{
		path: "pandianAdd",
		component: () => import("@/views/cangku/pandian/add.vue"),
	},
	{
		path: "gongyingshangList",
		component: () => import("@/views/cangku/gongyingshang/list.vue"),
	}
]
export default aaa;