const aaa = [
	{
		path: "orderList",
		component: () => import("@/views/order/order/orderList.vue"),
	},
	{
		path: "orderSee",
		component: () => import("@/views/order/order/orderSee.vue"),
	},
	{
		path: "rechargeOrderList",
		component: () => import("@/views/order/rechargeOrder/rechargeOrderList.vue"),
	},
	{
		path: "rechargeOrderSee",
		component: () => import("@/views/order/rechargeOrder/rechargeOrderSee.vue"),
	},
	{
		path: "returnOrderList",
		component: () => import("@/views/order/returnOrder/returnOrderList.vue"),
	},
	{
		path: "returnOrderSee",
		component: () => import("@/views/order/returnOrder/returnOrderSee.vue"),
	},
]
export default aaa;