import Vue from 'vue'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/theme/index.css"
import "./assets/css/reset.css"
import "./assets/css/resetElement.css"
// HS
import "./assets/css/HS/HS.scss"
import "./assets/css/HS/HSDialog.scss"

import App from './App.vue'
import router from "./router";
import store from "./store";
import $http from "./api/api.js";
import $public from "./util/public.js";
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
//用来判断按钮是否拥有权限
Vue.use(ElementUI);
const btnsRecursion = (btnsList, btns) => {
  btnsList.forEach((item) => {
    if (item) {
      if (item.children && item.children.length) {
        item.code && btns.push(item.code)
        btnsRecursion(item.children, btns)
      } else {
        item.code && btns.push(item.code)
      }
    }
  })
}
Vue.prototype.$buttonAuthority = (str) => {
  const menuListRoute = JSON.parse(sessionStorage.getItem("menuListRoute")||[])
  const jurisdiArr = []
  btnsRecursion(menuListRoute, jurisdiArr)
  const index = jurisdiArr.findIndex(item => item == str)
  if (index == -1) {
    return false
  }

  return true
}
Vue.config.productionTip = false
Vue.prototype.$http = $http;
Vue.prototype.$public = $public;
new Vue({
  render: h => h(App),
  router, store,
}).$mount('#app')
