const aaa = [
	{
		path: "xitong",
		component: () => import("@/views/dianpu/xitong/index.vue"),
	},
	{
		path: "dianpu",
		component: () => import("@/views/dianpu/dianpu/index.vue"),
	},
	{
		path: "dianyuanList",
		component: () => import("@/views/dianpu/dianyuan/list.vue"),
	},
	{
		path: "dianyuanAdd",
		component: () => import("@/views/dianpu/dianyuan/add.vue"),
	},
	{
		path: "pinpai",
		component: () => import("@/views/dianpu/pinpai/index.vue"),
	},
	{
		path: "jifen",
		component: () => import("@/views/dianpu/jifen/index.vue"),
	},
	{
		path: "note",
		component: () => import("@/views/dianpu/note/note.vue"),
	},
	{
		path: "template",
		component: () => import("@/views/dianpu/note/template.vue"),
	}

	// {
	// 	path: "huiyuanList",
	// 	component: () => import("@/views/dianpu/huiyuan/list.vue"),
	// },
	// {
	// 	path: "huiyuanAdd",
	// 	component: () => import("@/views/dianpu/huiyuan/add.vue"),
	// }
]
export default aaa;