const aaa = [
	{
		path: "huodongList",
		component: () => import("@/views/huodong/list.vue"),
	},
	{
		path: "huodongDetail",
		component: () => import("@/views/huodong/add.vue"),
	}
]
export default aaa;