const moment = require('moment')
const pub = {
	jia() {
		let re = 0;
		for (let i = 0; i < arguments.length; i++) {
			re = re + Number(arguments[i])
		}
		return this.toDoubleFixed2(re)
	},
	jian(num1, num2) {
		let re = num1 - num2
		return this.toDoubleFixed2(re)
	},
	cheng(num1, num2) {
		let re = num1 * num2
		return this.toDoubleFixed2(re)
	},
	chu(num1, num2) {
		let re = Number(num1) / Number(num2)
		return this.toDoubleFixed2(re)
	},
	jia_1() {
		let re = 0;
		for (let i = 0; i < arguments.length; i++) {
			re = re + Number(arguments[i])
		}
		return this.toDoubleFixed2_1(re)
	},
	jian_1(num1, num2) {
		let re = num1 - num2
		return this.toDoubleFixed2_1(re)
	},
	cheng_1(num1, num2) {
		let re = num1 * num2
		return this.toDoubleFixed2_1(re)
	},
	chu_1(num1, num2) {
		let re = Number(num1) / Number(num2)
		return this.toDoubleFixed2_1(re)
	},
	quyu(num1, num2) {
		let re = parseInt(Number(num1) * 100) % parseInt(Number(num2) * 100)
		return parseInt(re)
	},
	// 获取对象某一项（arr,key,keyvalue）
	getMatchingItem(arr, key, keyValue) {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i][key] == keyValue) {
				return arr[i]
			}
		}
		return {};
	},
	// 只能输入两位小数
	replaceToDouble(value) {
		value = String(value)
		//先把非数字的都替换掉，除了数字和.
		value = value.replace(/[^\d.]/g, "");
		//必须保证第一个为数字而不是. 
		value = value.replace(/^\./g, "");
		//保证只有出现一个.而没有多个. 
		value = value.replace(/\.{2,}/g, ".");
		//保证.只出现一次，而不能出现两次以上 
		value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
		return value;
	},
	// 只能输入整数
	replaceToInt(value) {
		value = String(value)
		value = value.replace(/[^\d]/g, "");
		return value;
	},
	// 格式化时间（时间戳,'YYYY-MM-DD HH:mm:ss'）
	FTime(str, str2) {
		str = parseInt(str)
		if (!str) return '';
		if (str.toString().length == 10 || str.toString().length == 9) {
			str = parseInt(str + '000')
		}
		if (!str2) {
			str2 = 'YYYY-MM-DD HH:mm:ss';
		}
		let date = new moment(parseInt(str));
		let formatDate = date.format(str2);
		return formatDate;
	},
	calculatePetAge(data) {
		if (data) {
			var g = data.replace(/-/g, "/")
			var birthday = new Date(g);
			var today = new Date();
			var time = Math.trunc((parseInt((today.getTime() - birthday.getTime()) / (1000 * 3600 * 24)) / 30));
			var year = Math.trunc(time / 365);
			if (time < 12) {
				return [year, time]
			}
			if (time == 12) {
				return [1, 0]
			} else {
				var n1 = Math.floor(time / 12);
				var n2 = time % 12;
				return [n1, n2]
			}
		}
	},
	// 年龄计算 (时间戳，时间戳)
	FAge(str, n2 = new Date().getTime()) {
		if (!str) return '';
		let d;
		if (str.length) {
			if (parseInt(str) < 10000) {
				d = new Date(str).getTime();
			} else {
				d = new Date(parseInt(str)).getTime();
			}
		} else {
			d = new Date(str).getTime();
		}
		let n1 = d.toString().length < 11 ? d * 1000 : d;
		n2 = n2.toString().length < 11 ? n2 * 1000 : n2;
		// 年月日时分秒
		return [new moment.duration(n2 - n1).years(), new moment.duration(n2 - n1).months(), new moment.duration(n2 - n1).days(), new moment.duration(n2 - n1).hours(), new moment.duration(n2 - n1).minutes(), new moment.duration(n2 - n1).seconds()];
	},
	// 获取间隔天数
	getIntervalDay(str, n2 = new Date().getTime()) {
		if (!str) return 0;
		let d;
		if (str.toString().length < 11) {
			d = new Date(str * 1000)
		} else {
			d = new Date(str * 1)
		}
		d = new Date(d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()).getTime()
		let n1 = d.toString().length < 11 ? d * 1000 : d;
		n2 = n2.toString().length < 11 ? n2 * 1000 : n2;
		return parseInt(new moment.duration(n2 - n1).asDays());
	},
	// 出生日期计算 (str 月数)
	FBirthday(p) {
		let m = new moment();
		return m.subtract(Number(p), 'months').format("YYYY-MM-DD");
	},
	// 手机号 
	isTel(tel) {
		if (!(/^1[3456789]\d{9}$/.test(tel))) {
			return false;
		} else {
			return true;
		}
	},
	// 验证邮箱
	isMail(mail) {
		var filter = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
		if (filter.test(mail)) {
			return true;
		} else {
			return false;
		}
	},
	// 保留两位小数
	toDoubleFixed2(num) {
		let num1 = Number((num * 100).toFixed(2));
		if (num > 0) {
			return Math.ceil(num1) / 100
		} else {
			return 0;
		}
	},
	toDoubleFixed2_1(num) {
		let num1 = Number((num * 100).toFixed(2));
		return Math.ceil(num1) / 100
	},
	// 是否为空
	isNull(str) {
		if (str === '' || str === null || str === undefined || Array.isArray(str) && str.length === 0 || str === {}) {
			return true;
		} else {
			return false;
		}
	},
	// 根据时间获取10位时间戳
	FTimeStr10(date) {
		if (this.isNull(date)) {
			return ''
		} else {
			let str = (new Date(date).getTime()).toString()
			return str.substr(0, str.length - 3)
		}
	},
	// 获取宠物类型 applyType
	getApplyType(str) {
		if (str === 'cat') {
			return '猫猫'
		} else if (str === 'dog') {
			return '狗狗'
		} else if (str === 'yichong') {
			return '其他'
		} else if (str === 'common') {
			return '通用'
		} else {
			return ''
		}
	},
	// 结算方式列表
	getPaymentList() {
		let list = [
			{ value: 0, label: '现金' },
			{ value: 1, label: '支付宝' },
			{ value: 2, label: '微信' },
			{ value: 3, label: 'pos机' },
			{ value: 4, label: '余额' },
			{ value: 8, label: '第三方' },
			{ value: 9, label: '优惠' },
		]
		return list
	},
	// 订单状态
	getOrderStateList() {
		let list = [
			{ value: 0, label: '已删除' },
			{ value: 1, label: '已创建' },
			{ value: 2, label: '支付中' },
			{ value: 3, label: '支付成功' },
			{ value: -2, label: '已取消' },
			{ value: -3, label: '退款中' },
			{ value: -4, label: '退款成功' },
		]
		return list
	},
	// 充值订单状态
	getRechargeOrderStateList() {
		let list = [
			{ value: 0, label: '已删除' },
			{ value: 1, label: '已创建' },
			{ value: 2, label: '支付中' },
			{ value: 3, label: '支付成功' },
			{ value: -2, label: '已取消' },
		]
		return list
	},
	// 退货订单状态
	getReturnOrderStateList() {
		let list = [
			{ value: 1, label: '创建退货' },
			{ value: 2, label: '退货中' },
			{ value: 3, label: '退货成功' },
			{ value: 0, label: '已删除' },
			{ value: -2, label: '已取消' },
		]
		return list
	},

}
export default pub;
