// import $public from '../../util/public.js'
export default {
	namespaced: true,
	state: {
		
	},
	getters: {
		
	},
	mutations: {
		
	},
	actions: {

	},
};
