const aaa = [
	{
		path: "supplyHome",
		component: () => import("@/views/supply/supplyHome.vue"),
	},
	{
		path: "supplyTypeList",
		component: () => import("@/views/supply/supplyTypeList.vue"),
	},
	{
		path: "supplyDetail",
		component: () => import("@/views/supply/supplyDetail.vue"),
	},	{
		path: "supplyCart",
		component: () => import("@/views/supply/supplyCart.vue"),
	},{
		path: "supplyCartDetail",
		component: () => import("@/views/supply/supplyCartDetail.vue"),
	},
	
	
]
export default aaa;