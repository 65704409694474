// import vue from 'vue'
import axios from 'axios';
import store from '../store/index.js';
import vue from 'vue'
axios.defaults.timeout = 50000;
axios.defaults.baseURL = process.env.VUE_APP_apiHost

axios.interceptors.request.use(function(config) {
	// config.headers['Content-Type'] = 'application/json;';
	config.headers['token'] = store.state.token||'';
	config.headers['hs-version'] = store.state.version;
	config.headers['hs-system'] = store.state.userInfo && store.state.userInfo.companyInfo && store.state.userInfo.companyInfo.id;
	config.headers['hs-shopId'] = store.state.userInfo && store.state.userInfo.nowShop && store.state.userInfo.nowShop.id;
	return config;
}, function(error) {
	console.log(error,'error');
	return Promise.reject(error);
});
axios.interceptors.response.use(function(response) {
	if (response.data) {
		if(response.data.type == "application/ms-excel"){
			return response;
		}else if(response.data.type == "application/octet-stream"){
			return response;
		}else if(response.data.type == "application/vnd.ms-excel"){
			return response;
		}else{
			switch (response.data.code) {
				case 0:
					return response;
				case -1:
					vue.prototype.$message({
						message: response.data.info,
						type: 'error'
					});
					return response;
				case 401:
					vue.prototype.$message({
						message: response.data.info,
						type: 'error'
					});
					vue.$router.replace({path:'/login'})
					return response;
				case 403:
					vue.prototype.$message({
						message: response.data.info,
						type: 'error'
					});
					vue.$router.replace({path:'/login'})
					return response;
				case 500:
					vue.prototype.$message({
						message: response.data.info,
						type: 'error'
					});
					return response;
				case 1000:
					vue.prototype.$message({
						message: response.data.info,
						type: 'error'
					});
					vue.$router.replace({path:'/login'})
					return response;
				default:
					vue.prototype.$message({
						message: response.data.info,
						type: 'error'
					});
					return response;
			}
		}
		
	}
}, function(error) {
	return Promise.reject(error);
});
export default {
	get(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.get(url, { params }).then(response => {
				let data;
				data = response.data
				resolve(data);
			}).catch(err => {
				reject(err)
			})
		})
	},
	post(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.post(url,params).then(response => {
				let data;
				data = response.data
				resolve(data);
			}).catch(err => {
				reject(err)
			})
		})
	},
	delete(url, params = {}) {
		return new Promise((resolve, reject) => {
			axios.delete(url, { params }).then(response => {
				let data;
				data = response.data
				resolve(data);
			}).catch(err => {
				reject(err)
			})
		})
	}
}
